import React from "react";
import Router from "./Router/index.jsx";

export default function App() {
  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
}
