import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { MapPin, MoveRight, Star } from "lucide-react";

const AgentOneDetails = ({
  className = "",
  agentOneDetailsJustifyContent,
  agentOneRatingBackgroundImage,
  image,
  agentName,
  agentDesciptionShort,
  rating,
  location,
  jobrole
}) => {
  const agentOneDetailsStyle = useMemo(
    () => ({
      justifyContent: agentOneDetailsJustifyContent,
    }),
    [agentOneDetailsJustifyContent]
  );

  const agentOneRatingStyle = useMemo(
    () => ({
      backgroundImage: agentOneRatingBackgroundImage,
    }),
    [agentOneRatingBackgroundImage]
  );

  return (
    <div
      className={`flex-1 rounded-3xl bg-white border-gainsboro-300 border-[2px] border-solid box-border overflow-hidden flex flex-row items-start justify-start pt-3.5 px-3.5 pb-3 gap-[27px] min-w-[408px] max-w-full text-left text-base text-white font-instrument-sans mq800:flex-wrap mq800:min-w-full ${className}`}
      style={agentOneDetailsStyle}
    >
      <AgentImageSection
        style={agentOneRatingStyle}
        // image={<Search size={48} />}
        rating={rating}
        
      />
      <AgentInfoSection  agentName={agentName}
        agentDesciptionShort={agentDesciptionShort}
        location={location}
        jobrole= {jobrole}
        />
    </div>
  );
};

const AgentImageSection = ({ style, image, rating }) => (
  <div
    className="bg-purple-600rounded-xl flex flex-row items-start justify-start pt-[223px] px-3 pb-3 box-border bg-cover bg-no-repeat bg-[top] min-w-[271px] mq800:flex-1"
    style={style}
  >
    {/* <Search size={20} /> */}
    {/* <img
      className="h-[271px] w-[271px] relative rounded-xl object-cover hidden"
      alt="Agent"
      src={image}
    /> */}
    <AgentRatingBadge rating={rating}  />
  </div>
);

const AgentRatingBadge = ({ rating }) => (
  <div className="h-9 bg-purple-600 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded-lg bg-blueviolet border-white border-[1px] border-solid box-border flex flex-row items-start justify-start py-1.5 px-[9px] gap-2.5 z-[1]">
    <Star fill="white" size={20} />

    <div className="relative font-medium">{rating}</div>
  </div>
);

const AgentInfoSection = ({agentName, agentDesciptionShort, location, jobrole}) => (
  <div className="flex-1 flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border min-w-[194px] text-lg text-black">
    <div className="self-stretch flex flex-col items-start justify-start gap-[26px]">
      <AgentDetails  agentName={agentName} location={location} jobrole={jobrole} />
      <AgentDescription  agentDesciptionShort = {agentDesciptionShort}/>
    </div>
  </div>
);

const AgentDetails = ({agentName, location, jobrole}) => (
  <div className="w-[258px] flex flex-col items-start justify-start gap-3">
    <h2 className="m-0 self-stretch relative text-[32px] tracking-[0.03em] font-semibold font-[inherit] mq800:text-7xl mq450:text-lgi">
      {agentName}
    </h2>
    <div className="self-stretch relative text-[14px] font-small">
      {jobrole}
    </div>
    <div className="flex flex-row items-center justify-start gap-2.5">
      <MapPin size={20} />
      <div className="relative font-medium">{location}</div>
    </div>
  </div>
);

const AgentDescription = ({ agentDesciptionShort }) => {
  // Function to trim the description to 20 words
  const getTrimmedDescription = (description) => {
    const words = description.split(" "); // Split the description into words
    return words.length > 15
      ? `${words.slice(0, 15).join(" ")}...` // Take the first 20 words and append dots
      : description; // Return the full description if it's 20 words or less
  };

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-3">
      <div className="relative leading-[26px]">
        {getTrimmedDescription(agentDesciptionShort)}
      </div>
      <div className="flex flex-row items-center justify-start gap-2.5 text-blue">
        <div className="relative text-purple-600 font-medium inline-block min-w-[95px]">
          See Details
        </div>
        <MoveRight color="#5600FF" size={20} />
      </div>
    </div>
  );
};


AgentOneDetails.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  agentOneDetailsJustifyContent: PropTypes.string,
  agentOneRatingBackgroundImage: PropTypes.string,
  rating: PropTypes.string.isRequired,
  agentName: PropTypes.string.isRequired,
  agentDesciptionShort: PropTypes.string.isRequired,
  location:PropTypes.string.isRequired,
  jobrole: PropTypes.string,
};

AgentImageSection.propTypes = {
  style: PropTypes.object,
  image: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
};

AgentRatingBadge.propTypes = {
  rating: PropTypes.string.isRequired,
};

AgentInfoSection.propTypes = {
  agentName: PropTypes.string.isRequired,
  agentDesciptionShort: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  jobrole: PropTypes.string,
};

AgentDetails.propTypes = {
  agentName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  jobrole: PropTypes.string,
};

AgentDescription.propTypes = {
  agentDesciptionShort: PropTypes.string.isRequired,
};

export default AgentOneDetails;
