const HeroBanner = ({ scrollToAgents }) => {
  return (
    <div className="relative bg-cover bg-center h-[88vh] rounded-3xl overflow-hidden">
      {/* Background Image */}
      <img
        className="absolute inset-0 w-full h-full object-cover"
        src="/images/banner2.jpg"
        alt="Hero Banner Background"
      />
      {/* Overlay for dull effect */}
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      <div className="relative z-10 flex flex-col justify-center h-full text-white px-16">
        <h1 className="text-5xl font-bold mb-4 leading-snug">
          Buyer Agents Work for YOU,
          <br /> Not the Seller
          <br /> Your Interests Come First!
        </h1>
        <p className="mb-8 text-lg leading-relaxed">
          Your one-stop platform for finding the right agent,
          <br /> tailored to your needs.
        </p>
        <button
          onClick={scrollToAgents}
          className="w-[15%] bg-purple-600 text-white py-3 px-6 rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          Find Agents
        </button>
      </div>
    </div>
  );
};

export default HeroBanner;
