import React, { useRef } from "react";
import AgentBenefits from "../components/AgentBenefits";
import HowItWorks from "../components/HowItWorks";
import TopRatedAgents from "../components/TopRatedAgents";
import Vetting from "../components/Vetting";
import Agents from "../components/Agents";
import Footer from "../components/Footer";
import HeaderComponent from "../components/Header";
import HeroBanner from "../components/HeroBanner";

const LandingPage = () => {
  const agentsRef = useRef(null);

  const scrollToAgents = () => {
    if (agentsRef.current) {
      agentsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <HeaderComponent />
      <div className="px-4">
        <HeroBanner scrollToAgents={scrollToAgents} />
      </div>
      <div className="py-16">
        <AgentBenefits />
      </div>
      <div className="p-16 mt-[-100px]">
        <HowItWorks
          image="/images/work.png"
          vector3="/vector-3.svg"
          empty="/empty.svg"
          empty1="/frame-1618873333.svg"
          empty2="/frame-1618873334.svg"
        />
      </div>

      {/* <div className="w-full h-[5156px] relative bg-white overflow-hidden flex flex-col items-end justify-start pt-[18px] px-4 pb-[567px] box-border gap-[18px] leading-[normal] tracking-[normal] mq1350:h-auto"> */}
      <TopRatedAgents />
      <div className="p-8 ">
        <Vetting
          image="/images/house2.png"
          fIndMyAgent="/images/findmyagent.svg"
          image1="/images/house3.png"
          image2="/images/house1.png"
        />
      </div>
      <div ref={agentsRef}></div>
      <Agents />

      <Footer
        fIndMyAgent="/images/findmyagent.svg"
        copyrightIcon="/vector-4.svg"
      />
      {/* </div> */}
    </>
  );
};

export default LandingPage;
